import * as React from "react"

import { Box, Button, Grid, HStack, Heading, Input, Select, SelectProps, SimpleGrid, Stack, Text, } from "@chakra-ui/react"
import { PageProps, graphql } from "gatsby"
import { blueFilters, genderFilter } from "../components/Shop/_data"
import { useEffect, useState } from 'react';

import { CheckboxFilter } from "../components/Shop/CheckboxFilter"
import InfiniteScroll from "react-infinite-scroll-component"
import Layout from "../components/Base/Layout"
import { MobileFilter } from "../components/Shop/MobileFilter"
import { PriceRangePicker } from "../components/Shop/PriceRangePicker"
import { ProductBreadcrumb } from "../components/Shop/ProductBreadcrumbs"
import { SingleProductCard } from "../components/Product/SingleProductCard"
import useAuth from "../components/hooks/useAuth"
import { useMemo } from "react"

const Shop: React.FC<Shop> = ({ data, pageContext }) => {

  const {
      allWpProduct: {
            nodes
          },
  } = data

  const [products, setProducts] = useState(nodes);
  // *SORT
  // initial array of all products

  function onSelectionChange(e: any) {
    // check which select value is chosen
    const sortDirection = e.target.value 

    // making a shallow copy of an initial array of products
    const copyArray = [...nodes]; 

    //sorting copy array based on which value is chosen
    copyArray.sort((a:any, b:any) => {

      if (sortDirection == 'a-z') {
        return a.name.localeCompare(b.name)
      }

      if (sortDirection == "z-a") {
        return b.name.localeCompare(a.name)
      }

      if (sortDirection == 'priceDESC') {
        const aPrice = Number(a.price.replace(/[^0-9.-]+/g,""));
        const bPrice = Number(b.price.replace(/[^0-9.-]+/g,""));

        return aPrice - bPrice
      }

      if (sortDirection == 'priceASC') {
        const aPrice = Number(a.price.replace(/[^0-9.-]+/g,""));
        const bPrice = Number(b.price.replace(/[^0-9.-]+/g,""));

        return bPrice - aPrice
      }

    });

    // re-render products
    setProducts(copyArray)

  }

  const [selectedPrice, setSelectedPrice] = useState([0, 5000]);
  const handleChangePrice = (value:any) => {
    setSelectedPrice(value);
  };

  // !PRICE SLIDER 

  const [filteredP, setFilteredP] = useState(nodes);
  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(0);
    const [error, setError] = useState(false);
  // price filter
  const PriceFilter = (e: any) => {

    if ((minPrice > maxPrice) || (maxPrice < minPrice)) {
      setError(true)
      setFilteredP(nodes)
    } else if (minPrice == 0 && maxPrice == 0 ) {
      setFilteredP(nodes)
      setError(false)
    } else {
      let list = nodes.filter(
        (item) => Number(item.price.replace(/[^0-9.-]+/g, "")) >= minPrice && Number(item.price.replace(/[^0-9.-]+/g, "")) <= maxPrice
    )
    setFilteredP(list)
    setError(false)
    }
  }


  const [filters, setFilters] = useState({
    categories: []
  })

  const showFilterResults = (filters:any) => {
    let updatedList = nodes;

    filters.includes("dGVybToyNjU=")
    
    if (filters.length) {
      updatedList = updatedList.filter((i:any) => filters.includes(i.productCategories.cat.map((j:any) => j.id)))
    }
    
  }

  const handleFilters = (filters:any, categories:any) => {

    const newFilters = { ...filters }
    newFilters[categories] = filters

    setFilters(newFilters)
    showFilterResults(newFilters[categories])
    
  }

  const [list, setList] = useState(nodes);

    const applyFilters = () => {
    let updatedList = nodes;


    // Price Filter
    const minPrice = selectedPrice[0];
    const maxPrice = selectedPrice[1];

    updatedList = updatedList.filter(
      (item) => Number(item.price.replace(/[^0-9.-]+/g, "")) >= minPrice && Number(item.price.replace(/[^0-9.-]+/g, "")) <= maxPrice
    );

      setList(updatedList);
  
  };

    useEffect(() => {
    applyFilters();
  }, [selectedPrice]);

  const { loggedIn } = useAuth();
  // const categoriesToExclude = ["Botulinum Toxins",  "Gynecology",  ]
  const categoriesToExclude = []

  const categoriesToHide = ["Body Sculpting", "Ophthalmology", "Orthopedic Injectables", "Filler Removal", "Ophthalmology", "Rheumatology", "Osteoporosis"]

  const filteredCategories = React.useMemo(() => {
    if(!loggedIn) {
      return products.filter((product) => {
         return product.productCategories.cat.some(category => !categoriesToExclude.includes(category.name)&& !categoriesToHide.includes(category.name)) && !product.productTags?.nodes.some((tag: any) => tag.name === 'unlicensed')
    })
    } else {
      return products.filter((product) => {
        return product.productCategories.cat.some(category => !categoriesToHide.includes(category.name))

      })
    }
  
    return products
  },[ products, loggedIn])

    const checkboxCategories = React.useMemo(() => {
      const productsCount = []

      filteredCategories.forEach(product => {
        product.productCategories.cat.forEach(productCategory => {
          const catIndex = productsCount.findIndex(item => item.id === productCategory.id)
          if(catIndex !== -1) {
            productsCount[catIndex].count += 1
          } else {
            productsCount.push({id: productCategory.id, name: productCategory.name, count: 1})
          }
        })
      })

      return productsCount
    },[filteredCategories])

    const filteredProducts = useMemo(() => {
      if(loggedIn) {
        return products.filter(item => item.productCategories.cat.some(category => category.slug === pageContext.slug))
      }
      return products.filter(item => item.productCategories.cat.some(category => category.slug === pageContext.slug && !categoriesToExclude.includes(category.name)) && !item.productTags?.nodes.some((tag: any) => tag.name === 'unlicensed'))
    },[products, loggedIn])

    const selectedCategory = useMemo(() => {
      const selectedProduct = filteredCategories.find(category => category.productCategories.cat.some(cat => cat.slug === pageContext.slug))
      return selectedProduct?.productCategories.cat.find(cat => cat.slug === pageContext.slug)
    },[filteredCategories])

  const ProductDisplay = () => { 

    if (loggedIn) {
      
      return <>{
        filteredProducts.map((item) => (
          <div key={item.id}>
            <SingleProductCard props={item}></SingleProductCard>
          </div>
        ))
      }</>
    } 

    if (!loggedIn && pageContext.slug !== "dermal-fillers") {
      return <><Text>Sign In or Register a New Account to View Products</Text></>
    } else {
         return <>{
        filteredProducts.map((item) => (
          <div key={item.id}>
            <SingleProductCard props={item}></SingleProductCard>
          </div>
        ))
      }</>
    }

    if (filteredProducts.length) {

      return <>{
        filteredProducts.map((item) => (
          <div key={item.id}>
            <SingleProductCard props={item}></SingleProductCard>
          </div>
        ))
      }</>
    } else if (filteredProducts.length == 0) {
      return <>{
        <Text>No products were found matching your selection.</Text>
      }</>
    }

  }

  return (
    <Layout>
        <Box width="100%" bg="white" mx="auto" px={{ base: '4', md: '8', lg: '12' }} py={{ base: '6', md: '8', lg: '12' }}>
          <Box mt={{ base: '8', md: '16' }}>
              <Grid templateColumns={{ base: '1fr', md: '240px 1fr' }} gap="14">
                <Stack spacing="10" maxW="240px" display={{ base: 'none', md: 'flex' }}>
              
                  <CheckboxFilter spacing="3" label="Category" handleFilters={(filters:any) => handleFilters(filters, "category")} categories={checkboxCategories} />
                       
                <Stack spacing="5">
                  <label>Price range</label>
                  
                {/* COMMENTED FOR NOW */}
                
                {/* <PriceRangePicker value={[0, 5000]} changePrice={handleChangePrice} />
                
                 <HStack spacing="6">
                  
                      <Input name="minPrice" value={selectedPrice[0]} onChange={handleChangePrice} type="number" placeholder="$500" />
                      <Input name="maxPrice" value={selectedPrice[1]} onChange={handleChangePrice} type="number" placeholder="$1,000" />
                  
                    </HStack> */}
                  
                  {/* PRICE RANGE INPUT FIELDS */}
                  <form>
                  <HStack spacing="6">
                  
                      <Input name="minPrice" defaultValue={minPrice} onChange={event => setMinPrice(event.target.value)} type="number" placeholder="$500" />
                      <Input name="maxPrice" defaultValue={maxPrice} onChange={event => setMaxPrice(event.target.value)} type="number" placeholder="$1,000" />
                  
                    </HStack>
                    {error ? <Text color="red">Wrong input!</Text> : <></>}
                      <Button mt='24px' type="button" onClick={PriceFilter} w="100%">Apply price filter</Button>
                    </form>
                  </Stack>
                  </Stack>

                  <Box width="full">
                    <Stack spacing={{ base: '6', md: '4' }} direction={{ base: 'column', md: 'row' }} justify="space-between" align="flex-start" width="full" >
                      <Stack direction={{ base: 'column', md: 'row' }} align="baseline">
                        <Heading size="md" fontSize="2xl">
                        {selectedCategory?.name}
                        </Heading>
                        <Text color="gray.500">  ({ filteredProducts.length } products) </Text>
                      </Stack>
                      <MobileFilter />
                      <HStack display={{ base: 'none', md: 'flex' }}>
                        <Text flexShrink={0} color="gray.500" fontSize="sm">Sort by</Text>
                  
                          {/* <SortbySelect /> */}
                          <Select size="sm" aria-label="Sort by" rounded="md" onChange={onSelectionChange}>
                            <option value="a-z">Name: A-Z</option>
                            <option value="z-a">Name: Z-A</option>
                            <option value="priceASC">Price: DESC</option>
                            <option value="priceDESC">Price: ASC</option>
                          </Select>
                      </HStack>
                    </Stack>
                           
                    <Box mt="6" minH="480px" rounded="xl">
                      <SimpleGrid columns={{base: 1, md: 2, lg:3}}  columnGap={{ base: '4', md: '6' }} rowGap={{ base: '8', md: '10' }} >
                        {/* DISPLAY PRODUCTS */}
                        {ProductDisplay()}
                       </SimpleGrid>
                    </Box>
                  </Box>
                </Grid>
            </Box>
      </Box>
    </Layout>
  )
}

type Shop = PageProps<ShopProps>

type ShopProps = {
  // data: {
    allWpProduct: {
        totalCount: number,
        nodes: Array<{ name: string; id: string; slug: string; price: string; databaseId: number; image: { sourceUrl?: string }, productCategories: {cat: [{name: string, id: string}]} }>
        pageInfo: { currentPage: number, hasNextPage: boolean, hasPreviousPage: boolean, itemCount: number, perPage: number, totalCount: number, pageCount: number },
    },
  // }
}

export const query = graphql`
  query AllProductsShop {
    allWpProduct(sort: {fields: name, order: ASC}) {
    totalCount
    nodes {
      name
      id
      slug
      databaseId
      productTags {
        nodes {
          name
        }
      }
      productCategories {
        cat: nodes {
          name
          id
          slug
        }
      }
      productTags {
        nodes {
        name
        id
        slug
        }
    }
      image {
        sourceUrl
      }
      ... on WpSimpleProduct {
        id
        name
        price
      }
    }
     pageInfo {
      currentPage
      hasNextPage
      itemCount
      totalCount
      perPage
      hasPreviousPage
      pageCount
    }
  }
  }
`

export default Shop